import HeaderCom from '../../../components/M-header.vue';
import { bizURL } from "@/config/baseUrl";

export default {
  name: 'adminEnter',
  components: {
    HeaderCom
  },
  data() {
    return {
      total: 0, // 总条目数
      currentPage: 1, // 当前页
      limit: 10, // 每页显示数量
      loading: false,
      title: '查看考场考生人数', // 弹窗文字
      editTitle: '录入考生成绩', // 弹窗文字
      formInline: {},
      file: '',
      importUrl: bizURL + "/registration/examResult/importAchievement",
      uploadData: { Authorization: sessionStorage.getItem("manageToken") },
      stateList: [{ value: '0', label: '未完成' }, { value: '1', label: '已完成' }],
      subjectList: JSON.parse(sessionStorage.getItem('subjectList')), // 考试科目
      selections: [], // 表格被选中的内容
      tabColumns: [
        { type: 'selection', width: 50, align: 'center' },
        { title: '序号', type: 'index', width: 50, align: 'center' },
        { title: '考试计划流水号', key: 'examPlanNo', align: 'center', tooltip: true, minWidth: 150 },
        { title: '考试科目', key: 'examMajorTitle', align: 'center', tooltip: true, minWidth: 150 },
        { title: '考试开始时间', key: 'examBeginTime', align: 'center', tooltip: true, resizable: true, width: 110 },
        { title: '考试结束时间', key: 'examEndTime', align: 'center', tooltip: true, resizable: true, width: 110 },
        { title: '考场号', key: 'examRoom', align: 'center', width: 100 },
        { title: '考试地点', key: 'examAddress', align: 'center', tooltip: true, minWidth: 120 },
        { title: '考场考生人数', slot: 'examPersonCount', align: 'center', width: 120 },
        { title: '已录入学员', slot: 'recordedData', align: 'center', width: 100 },
        { title: '未录入学员', slot: 'unrecordedData', align: 'center', width: 100 },
        { title: '计划发布时间', key: 'submitDate', align: 'center', tooltip: true, resizable: true, width: 110 },
        { title: '操作', slot: 'action', width: 130, align: 'center', fixed: 'right' }
      ],
      tabData: [],
      markModal: false, // 录入考生成绩
      examIndex: -1,  // 当前聚焦的输入框的行数
      editExam: '', // 合格分数输入框
      markColumns: [
        { title: '序号', type: 'index', width: 50, align: 'center' },
        { title: '准考证号', key: 'examTicketNo', align: 'center', width: 150 },
        { title: '姓名', key: 'regName', align: 'center', width: 120 },
        { title: '性别', key: 'gender', align: 'center', width: 70 },
        { title: '身份证号', key: 'idNumber', align: 'center', tooltip: true, resizable: true, width: 180 },
        { title: '考试成绩', slot: 'achievement', align: 'center', width: 120 },
        {
          title: '录入状态', key: 'type', align: 'center', width: 120,
          render: (h, params) => {
            let tmpStr = "";
            if (params.row.achievement) {
              tmpStr = "已录入";
            } else {
              tmpStr = "未录入"; // #19be6b
            }
            return h('span', {
              style: {
                color: tmpStr == '已录入' ? "#19be6b" : "#ed3f14"
              }
            }, tmpStr)
          }
        },
        { title: '考试科目', key: 'subjectName', align: 'center', tooltip: true, resizable: true, width: 240 },
        { title: '测试级别', key: 'examMajorTitle', align: 'center', width: 120 },
        { title: '工作单位', key: 'unitName', align: 'center', tooltip: true, resizable: true, width: 150 },
        { title: '考试地点', key: 'examAddress', align: 'center', tooltip: true },
        { title: '操作', slot: 'action', width: 150, align: 'center' }
      ],
      markData: [],
      studentModal: false, // 查看考场考生
      studentColumn: [
        { title: '序号', type: 'index', width: 50, align: 'center' },
        { title: '准考证号', key: 'examTicketNo', align: 'center', width: 150 },
        { title: '姓名', key: 'regName', align: 'center', width: 120 },
        { title: '性别', key: 'gender', align: 'center', width: 70 },
        { title: '身份证号', key: 'idNumber', align: 'center', tooltip: true, resizable: true, width: 180 },
        { title: '考试成绩', key: 'achievement', align: 'center', width: 120 },
        {
          title: '录入状态', key: 'type', align: 'center', width: 120,
          render: (h, params) => {
            let tmpStr = "";
            if (params.row.achievement) {
              tmpStr = "已录入";
            } else {
              tmpStr = "未录入"; // #19be6b
            }
            return h('span', {
              style: {
                color: tmpStr == '已录入' ? "#19be6b" : "#ed3f14"
              }
            }, tmpStr)
          }
        },
        { title: '考试科目', key: 'subjectName', align: 'center', tooltip: true, resizable: true, width: 240 },
        { title: '测试级别', key: 'examMajorTitle', align: 'center', width: 120 },
        { title: '工作单位', key: 'unitName', align: 'center' , tooltip: true, resizable: true, width: 150},
        { title: '考试地点', key: 'examAddress', align: 'center', tooltip: true }
      ],
      studentData: [],
      importModal: false,
      importForm: {},
      multiple: false, // 是否支持多选文件
      publishTime: {
        shortcuts: [
          {
            text: '今天',
            value() {
              return new Date();
            }
          },
          {
            text: '明天',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              return date;
            }
          },
          {
            text: '一周后',
            value() {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              return date;
            }
          }
        ]
      },
      examTime: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '最近一个月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: '最近三个月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      },
      batchList: [],//批次
    }
  },
  mounted() {
    // this.getData();
    this.getBatchOptions();
  },
  methods: {
    //批次list  同步方法必须先执行他
     getBatchOptions() {
       this.$manageHttp.getBatchOptions().then(data => {
        if (data.code == '200') {
          this.batchList = data.data;
          this.formInline.batchId=this.batchList[0].id;
          this.getData();
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {
        this.$Message.error(error);
      })
    },

    // 清空上传文件列表
    clearFiles(fileName) {
      this.$refs[fileName].clearFiles();
    },
    onSearch() {
      this.currentPage = 1;
      this.getData();
    },
    // 页码改变的回调，返回改变后的页码(当前页)
    sizeChange(val) {
      this.currentPage = val;
      this.getData();
    },
    // 切换每页条数时的回调，返回切换后的每页条数(每页数)
    pageChange(val) {
      this.limit = val;
      this.getData();
    },
    planChange(val) {
      console.log(val, 'val');
      this.formInline.submitDate = val;
    },
    // 获取列表数据
    getData() {
      this.loading = true;
      var params = {
        pageNum: this.currentPage,
        pageSize: this.limit,
        examPlanNo: this.formInline.examPlanNo,
        examMajorTitle: this.formInline.examMajorTitle,
        examRoom: this.formInline.examRoom,
        examAddress: this.formInline.examAddress,
        submitDate: this.formInline.submitDate,
        complete: this.formInline.complete,
        batchId:this.formInline.batchId
      }
      this.$manageHttp.getExamResultsList(params).then(data => {
        if (data.code == '200') {
          this.loading = false;
          this.tabData = data.rows;
          this.total = data.total;
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {

      })
    },
    // 选中某一项触发
    selectChange(selection) {
      this.selections = selection;
      this.ids = [];
      this.selections.forEach(item => {
        this.ids.push(item.id);
      })
    },
    // 导出筛选名单
    exportList() {
      var params = {
        ids: this.ids + ','
      };
      if (this.selections.length) {
        this.$manageHttp.exportRegistration(params).then(data => {
          if (data.code == '200') {
            this.$manageHttp.download(data.msg);
          } else {
            this.$Message.error(data.msg);
          }
        }).catch(error => {
          this.$Message.error(error);
        })
      } else {
        this.$Message.warning('请先选择要生成准考证的数据!');
      }
    },
    // 计划考生列表
    examList(row) {
      this.$manageHttp.getExamRegistrationList({
        examPlanId: row.id
      }).then(data => {
        if (data.code == '200') {
          this.studentData = data.data.data;
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {
        this.$Message.error(error);
      })
    },
    // 计划考生列表(可编辑)
    editList(row, type) {
      this.examPlanId = row.id;
      this.$manageHttp.getExamRegistrationList({
        examPlanId: row.id,
        type: type
      }).then(data => {
        if (data.code == '200') {
          this.markData = data.data.data;
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {
        this.$Message.error(error);
      })
    },

    // 成绩录入---修改
    examEdit(row, index) {
      this.editExam = row.achievement;
      this.examIndex = index;
      this.examResultsStr = row.regId;
      this.$nextTick(() => {
        this.$refs.scoreFocus.focus();
      })
    },
    // 成绩录入---保存
    examSave(index) {
      var that = this;
      if (that.editExam == '') {
        this.$Message.warning('请输入成绩!');
        return false;
      }
      this.examResultsStr = this.examResultsStr + ',' + that.editExam + ';';
      this.$manageHttp.updateExamRegistration({
        examPlanId: this.examPlanId,
        examResultsStr: this.examResultsStr,
      }).then(data => {
        console.log(data, 'data');
        if (data.code == '200') {
          that.markData[index].achievement = that.editExam;
          that.examIndex = -1;
          this.$Message.success(data.msg);
          this.getData();
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {

      })
    },
    // 文件超出指定大小限制时的钩子
    handleProgress(event, file, fileList) {
      console.log(file, 'file');
      console.log(fileList, 'fileList');
      if (fileList.length >= 2) {
        this.$Message.error({ background: true, content: `只能上传1个文件,当前共选择了 ${fileList.length} 个` });
        return false;
      }
    },
    handleUpload(file) {
      this.file = file;
      // return false;
    },
    // 批量导入
    importSuccess(res, file) {
      // this.file = file;
      console.log(res, 'res');
      console.log(file, 'success');
      if (res.code == '200') {
        this.$Message.success(res.msg);
        setTimeout(() => {
          // this.$refs.myfiles.clearFiles();
          this.file = null;
        }, 1500)
        this.getData();
        // this.importModal = false;
      } else {
        this.$Message.error('抱歉！导入失败！' + res.msg);
      }

      // this.frontData = file;
      // file.url = res.url;
      // file.name = res.fileName;
      // this.infoForm.idcardHead = res.url;
      // this.$refs.photo.clearFiles(); // 清空头像
    },
    // 导入成绩---确定
    importSure() {
      if (!this.file) {
        this.$Message.warning("请先上传文件！");
      }
      // else {
      //   // this.$refs.myfiles.post(this.file);
      // }
    },
  }
}